
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { GetServerSideProps, GetServerSidePropsContext } from 'next';
import { CreateArticle, Props, State } from '@admin/templates';
import { getNewsCategories } from '@admin/templates/news-category/helpers';
import { populateContextData } from '@common/defaults';
const getServerSideProps: GetServerSideProps<Props> = async (context: GetServerSidePropsContext) => {
    const contextData = await populateContextData(context);
    const state: State = {
        dataIsOutdated: false
    };
    const props: Props = {
        contextData,
        state,
        categories: []
    };
    props.categories = await getNewsCategories({ contextData, isClientSide: false });
    return {
        props
    };
};
export const Home = CreateArticle;
export default Home;

    async function __Next_Translate__getServerSideProps__193b9fdff7d__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__193b9fdff7d__ as getServerSideProps }
  